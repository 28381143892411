// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../../utils/theme/font-weights"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getOptions } from "../../../rixhtext-options"

const ServiceDescriptionItems = ({ agencyServiceDescription }) =>
  agencyServiceDescription.map((service, i) => {
    const { serviceDescriptionItemTitle, serviceDescriptionItemText } = service

    const serviceDescription = renderRichText(
      serviceDescriptionItemText,
      getOptions()
    )

    return (
      <div
        key={i}
        id={`${serviceDescriptionItemTitle
          .toLowerCase()
          .replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "")
          .replace(/\s/g, "-")}`}
        sx={{
          mb:
            agencyServiceDescription.length === i + 1
              ? "0"
              : ["32px", "64px", "64px"],
        }}
      >
        <h2
          sx={{
            color: "#100720",
            fontSize: ["18px", "22px", "32px"],
            fontWeight: fontWeights.extraBold,
            mb: ["16px", "32px", "43px"],
          }}
        >
          {serviceDescriptionItemTitle}
        </h2>
        {serviceDescription}
      </div>
    )
  })

export default ServiceDescriptionItems
