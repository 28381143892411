// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { fontWeights } from "../../../utils/theme/font-weights"

const ServiceHeader = ({ langKey, agencyServiceName }) => (
  <div
    sx={{
      maxWidth: ["700px", "700px", "1132px"],
      mb: "32px",
    }}
  >
    <Link
      to={langKey === "fr" ? `${langKey}/amazon-agency` : "/amazon-agency"}
      sx={{
        color: "#100720",
        textDecoration: "none",
        fontSize: ["18px", "22px", "22px"],
        fontWeight: fontWeights.bold,
        opacity: 0.25,
      }}
    >
      {langKey === "fr" ? "< Agencie" : "< Agency"}
    </Link>
    <h1
      sx={{
        fontSize: ["28px", "36px", "48px"],
        fontWeight: fontWeights.bold,
        mt: ["32px", "64px", "64px"],
        mb: "0",
      }}
    >
      {agencyServiceName}
    </h1>
  </div>
)

export default ServiceHeader
