// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { fontWeights } from "../../../utils/theme/font-weights"

const ServiceDescriptionCard = ({ langKey, descriptionCardData }) => {
  const { serviceIcon, serviceTitle, serviceDescription } = descriptionCardData

  return (
    <Link
      to={
        langKey === "fr"
          ? `/${langKey}/services/${serviceTitle
              .replace(" ", "-")
              .toLowerCase()}`
          : `/services/${serviceTitle.replace(" ", "-").toLowerCase()}`
      }
      sx={{
        display: "flex",
        flexDirection: "row",
        textDecoration: "none",
        color: "inherit",
        backgroundColor: "#FFF9F0",
        borderRadius: "8px",
        width: "100%",
        maxWidth: ["375px", "553px", "553px"],
        px: ["32px", "60px", "60px"],
        py: ["43px", "75px", "75px"],
      }}
    >
      <div>
        <Img
          alt=""
          fluid={serviceIcon.fluid}
          sx={{
            width: ["22px", "42px", "42px"],
            mt: ["5px", "0", "0"],
            mr: ["16px", "32px", "32px"],
          }}
        />
      </div>
      <div sx={{ textAlign: "left", width: "340px" }}>
        <p
          sx={{
            fontSize: ["18px", "28px", "28px"],
            fontWeight: fontWeights.extraBold,
            mb: ["8px", "8px", "16px"],
          }}
        >
          {serviceTitle}
        </p>
        <p sx={{ fontSize: ["15px", "18px", "18px"], opacity: 0.9, mb: "0" }}>
          {serviceDescription}
        </p>
      </div>
    </Link>
  )
}

export default ServiceDescriptionCard
