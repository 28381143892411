// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"
import ServiceDescriptionCard from "./components/ServiceDescriptionCard"

const OtherServices = ({ langKey, otherServices, agencyServiceName }) => {
  const {
    otherServicesTitle,
    otherServicesDescription,
    otherServicesDescriptionCards,
  } = otherServices

  return (
    <div
      sx={{
        width: ["100%", "94%", "100%"],
        maxWidth: ["500px", "600px", "100%"],
        mx: "auto",
        textAlign: "center",
        mb: "64px",
      }}
    >
      <p
        sx={{
          fontSize: ["22px", "36px", "36px"],
          fontWeight: fontWeights.extraBold,
          mb: "16px",
        }}
      >
        {otherServicesTitle}
      </p>
      <p
        sx={{
          fontSize: ["15px", "18px", "18px"],
          fontWeight: fontWeights.normal,
          mb: ["32px", "32px", "64px"],
        }}
      >
        {otherServicesDescription}
      </p>

      <div sx={{ display: "flex", justifyContent: "center" }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gap: "16px",
          }}
        >
          {otherServicesDescriptionCards
            .filter(service => service.serviceTitle !== agencyServiceName)
            .map((descriptionCardData, i) => (
              <ServiceDescriptionCard
                key={i}
                langKey={langKey}
                descriptionCardData={descriptionCardData}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default OtherServices
