// eslint-disable-next-line no-unused-vars
import React, { useState } from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

import { fontWeights } from "../../utils/theme/font-weights"

import ServiceHeader from "./components/ServiceHeader"
import ServiceDescriptionNavigationLink from "./components/ServiceDescriptionNavigationLink"
import ServiceDescriptionItems from "./components/ServiceDescriptionItems"

const ServiceInformation = ({
  langKey,
  heroSectionBg,
  agencyServiceHero,
  agencyServiceDescription,
}) => {
  const { agencyServiceName, agencyServiceMainImage } = agencyServiceHero

  const serviceDescriptionTitles = agencyServiceDescription.map(
    service => service.serviceDescriptionItemTitle
  )

  const [activeServiceDescription, setActiveServiceDescription] = useState(
    `${serviceDescriptionTitles[0]}`
  )

  const handleActiveDescription = descriptionTitle => {
    setActiveServiceDescription(descriptionTitle)
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        columnGap: ["0", "0", "35px"],
        pt: "128px",
        mb: "64px",
      }}
    >
      <div sx={{ position: "sticky", top: "100px", height: "60%" }}>
        <div
          sx={{
            display: ["none", "none", "flex"],
            flexDirection: "column",
            width: "200px",
            mt: "190px",
          }}
        >
          {serviceDescriptionTitles.map((serviceTitle, i) => (
            <ServiceDescriptionNavigationLink
              key={i}
              langKey={langKey}
              isLastElement={serviceDescriptionTitles.length === i + 1}
              agencyServiceName={agencyServiceName}
              serviceTitle={serviceTitle}
              activeServiceDescription={activeServiceDescription}
              handleActiveDescription={handleActiveDescription}
            />
          ))}
        </div>
      </div>

      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["100%", "100%", "76%"],
          maxWidth: ["700px", "700px", "1132px"],
          mx: "auto",
        }}
      >
        <ServiceHeader
          langKey={langKey}
          agencyServiceName={agencyServiceName}
        />
        <p
          sx={{
            color: "#100720",
            fontSize: ["15px", "18px", "18px"],
            lineHeight: ["22px", "30px", "30px"],
            fontWeight: fontWeights.normal,
            opacity: 0.8,
            mb: ["22px", "30px", "30px"],
          }}
        >
          {agencyServiceHero.agencyServiceDescription.agencyServiceDescription}
        </p>
        <Img
          alt=""
          fluid={agencyServiceMainImage.fluid}
          sx={{ borderRadius: "8px", mb: "64px" }}
        />
        <ServiceDescriptionItems
          agencyServiceDescription={agencyServiceDescription}
        />
      </div>
    </div>
  )
}

export default ServiceInformation
