// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

import { fontWeights } from "../../utils/theme/font-weights"
import ContactRedirection from "../contact-redirection"
import ContactForm from "../common/ContactForm"

const Contact = ({ langKey, contactSection, serviceName }) => {
  const { contactInfoTitle, contactInfoDescription } = contactSection

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: ["column", "column", "row"],
        justifyContent: "space-evenly",
        width: ["92%", "89%", "100%"],
        maxWidth: ["414px", "572px", "100%"],
        margin: "auto",
        mt: "64px",
        mb: "128px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: ["100%", "100%", "25%"],
          textAlign: ["center", "center", "center"],
        }}
      >
        <p
          sx={{
            fontSize: ["22px", "36px", "48px"],
            fontWeight: fontWeights.extraBold,
            mb: ["8px", "16px", "16px"],
          }}
        >
          {contactInfoTitle}
        </p>
        <p
          sx={{
            fontSize: "18px",
            fontWeight: [
              fontWeights.normal,
              fontWeights.bold,
              fontWeights.bold,
            ],
            mb: ["64px", "64px", "0"],
          }}
        >
          {contactInfoDescription}
        </p>
        <ContactRedirection
          href={
            langKey === "en"
              ? "https://www.jellyfish.com/en-us/contact-us"
              : "https://www.jellyfish.com/fr-fr/contact-us"
          }
          buttonContent={"Book Demo"}
          overrideCSS={{ width: "fit-content", mt: "20px" }}
        />
      </div>
      {/* <ContactForm source={`Service: ${serviceName}`} /> */}
    </div>
  )
}

export default Contact
