// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { fontWeights } from "../../../utils/theme/font-weights"

const serviceDescriptionNavLink = ({
  langKey,
  agencyServiceName,
  isLastElement,
  serviceTitle,
  activeServiceDescription,
  handleActiveDescription,
}) => {
  const agencyServiceNameToLink = `services/${agencyServiceName
    .toLowerCase()
    .replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "")
    .replace(/\s/g, "-")}#${serviceTitle
    .toLowerCase()
    .replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "")
    .replace(/\s/g, "-")}`

  return (
    <AnchorLink
      to={
        langKey === "fr"
          ? `/fr/${agencyServiceNameToLink}`
          : `/${agencyServiceNameToLink}`
      }
      title={serviceTitle}
      sx={{
        position: "sticky",
        color: "#100720",
        textDecoration: "none",
        fontSize: "18px",
        fontWeight:
          activeServiceDescription === serviceTitle
            ? fontWeights.bold
            : fontWeights.normal,
        lineHeight: "24px",
        mb: isLastElement ? "0" : "16px",
      }}
      onAnchorLinkClick={() => handleActiveDescription(serviceTitle)}
    />
  )
}

export default serviceDescriptionNavLink
