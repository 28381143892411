// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import BackgroundImage from "gatsby-background-image"

import MetaDataQuery from "../components/common/meta/MetaData"
import Layout from "../components/layout/layout"
import Container from "../components/layout/container"
import ServiceInformation from "../components/services/ServiceInformation"
import ServiceSuccessStory from "../components/services/ServiceSuccessStory"
import Contact from "../components/services/Contact"
import OtherServices from "../components/services/OtherServices"

const Service = ({ data, location, pageContext }) => {
  const langKey = pageContext.langKey
  const { contentfulAgencyServicesTemplate, allContentfulFooter } = data
  const {
    agencyServiceHero,
    agencyServiceDescription,
    agencyServiceSuccessStory,
    contactSection,
    otherServices,
  } = contentfulAgencyServicesTemplate

  const { agencyServiceName } = agencyServiceHero

  const contactAndOtherServicesSectionBg =
    data.contactAndServiceBgImage.childImageSharp.fluid
  const heroSectionBg = data.heroBgImage.childImageSharp.fluid

  return (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <MetaDataQuery location={location} />
      <Layout langKey={langKey} allContentfulFooter={allContentfulFooter}>
        <BackgroundImage
          Tag="section"
          fluid={heroSectionBg}
          sx={{ backgroundRepeat: "no-repeat" }}
        >
          <Container>
            <ServiceInformation
              langKey={langKey}
              heroSectionBg={heroSectionBg}
              agencyServiceHero={agencyServiceHero}
              agencyServiceDescription={agencyServiceDescription}
            />
          </Container>
        </BackgroundImage>

        <Container>
          <ServiceSuccessStory
            langKey={langKey}
            agencyServiceSuccessStory={agencyServiceSuccessStory}
            origin={location.origin}
          />
          <BackgroundImage
            Tag="section"
            fluid={contactAndOtherServicesSectionBg}
          >
            <Contact
              langKey={langKey}
              contactSection={contactSection}
              serviceName={agencyServiceHero.agencyServiceName}
            />
            <OtherServices
              langKey={langKey}
              otherServices={otherServices}
              agencyServiceName={agencyServiceName}
            />
          </BackgroundImage>
        </Container>
      </Layout>
    </>
  )
}

export default Service

export const serviceTemplateData = graphql`
  query ServiceTemplateQuery($id: String!, $langKey: String) {
    heroBgImage: file(relativePath: { eq: "ServicesHeroBG.png" }) {
      childImageSharp {
        fluid(maxHeight: 1144) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contactAndServiceBgImage: file(
      relativePath: { eq: "Form&ServicesBg.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulAgencyServicesTemplate(id: { eq: $id }) {
      agencyServiceHero {
        agencyServiceName
        agencyServiceDescription {
          agencyServiceDescription
        }
        agencyServiceMainImage {
          fluid(maxWidth: 1400) {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            sizes
          }
        }
      }
      agencyServiceDescription {
        serviceDescriptionItemTitle
        serviceDescriptionItemText {
          raw
        }
      }
      agencyServiceSuccessStory {
        successStoryTitle
        successStoryDescription
        linkToTheSuccessStory
        clientLogo {
          fixed(width: 120) {
            width
            srcWebp
            srcSet
            srcSetWebp
            src
            height
            base64
            aspectRatio
          }
        }
        successStoryImage {
          fluid(maxWidth: 670) {
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            sizes
          }
        }
      }
      contactSection {
        contactInfoTitle
        contactInfoDescription
      }
      otherServices {
        otherServicesTitle
        otherServicesDescription
        otherServicesDescriptionCards {
          serviceIcon {
            fluid(maxWidth: 42) {
              base64
              src
              srcSet
              srcSetWebp
              srcWebp
              aspectRatio
              sizes
            }
          }
          serviceTitle
          serviceDescription
        }
      }
    }
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
