import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { Link } from "gatsby"

export const getOptions = () => ({
  renderMark: {
    [MARKS.BOLD]: children => <b>{children}</b>,
    [MARKS.ITALIC]: children => <i>{children}</i>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <span
          sx={{
            fontSize: ["15px", "18px", "18px"],
            lineHeight: ["22px", "30px", "30px"],
            opacity: 0.9,
            mb: "32px",
          }}
        >
          {children}
        </span>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} sx={{ color: "#FFA41B", textDecoration: "none" }}>
        {children}
      </a>
    ),
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <ul
          sx={{
            display: "grid",
            gridTemplateColumns: "auto",
            rowGap: "16px",
            columnGap: "32px",
            mt: "32px",
            mb: "0",
            "& > li": {
              fontSize: ["15px", "18px", "18px"],
              lineHeight: ["22px", "30px", "30px"],
              opacity: 0.9,
              mb: "0",
            },
            "& ::marker": { color: "#FFA41B" },
          }}
        >
          {children}
        </ul>
      )
    },
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol
        sx={{
          display: "grid",
          gridTemplateColumns: "auto",
          rowGap: "16px",
          columnGap: "32px",
          mt: "32px",
          mb: "0",
          "& > li": {
            fontSize: ["15px", "18px", "18px"],
            lineHeight: ["22px", "30px", "30px"],
            opacity: 0.9,
            mb: "0",
          },
          "& ::marker": { color: "#FFA41B" },
        }}
      >
        {children}
      </ol>
    ),
  },
})
