// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { fontWeights } from "../../utils/theme/font-weights"

const ServiceSuccessStory = ({
  langKey,
  agencyServiceSuccessStory,
  origin,
}) => {
  const {
    clientLogo,
    successStoryTitle,
    successStoryDescription,
    successStoryImage,
    linkToTheSuccessStory,
  } = agencyServiceSuccessStory

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "#FFF9F0",
        borderRadius: "8px",
        py: "64px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: ["100%", "100%", "33%"],
          minWidth: ["245px", null, null],
          maxWidth: ["78%", "75%", "450px"],
          alignItems: ["center", "center", "start"],
          textAlign: ["center", "center", "left"],
        }}
      >
        <Img alt="" fixed={clientLogo.fixed} sx={{ mb: "32px" }} />
        <p
          sx={{
            fontSize: ["22px", "22px", "28px"],
            fontWeight: fontWeights.black,
          }}
        >
          {successStoryTitle}
        </p>
        <p
          sx={{
            fontSize: ["15px", "18px", "18px"],
            lineHeight: "30px",
            mb: "32px",
            opacity: 0.9,
          }}
        >
          {successStoryDescription}
        </p>
        <Link
          to={`${origin}/${linkToTheSuccessStory}`}
          sx={{
            color: "#FFA41B",
            textDecoration: "none",
            fontSize: "18px",
            fontWeight: fontWeights.bold,
            ":hover": {
              "& > span": {
                pl: "16px",
              },
            },
          }}
        >
          {langKey === "fr" ? "En savoir plus" : "Discover"}
          <span sx={{ pl: "8px", transition: "padding 500ms ease" }}>→</span>
        </Link>
      </div>
      <Img
        alt=""
        fluid={successStoryImage.fluid}
        sx={{ width: "41%", display: ["none", "none", "inherit"] }}
      />
    </div>
  )
}

export default ServiceSuccessStory
